<template>
  <v-app>
    <div class="page-login">
      <pg-header />
      <div class="pg-container">
        <div class="pg-content">
          <h3>ログイン</h3>

          <div class="pg-form-container">

            <v-form @submit="onSubmit">

              <v-alert :value="errors != null" color="error" dark transition="scale-transition" class="pg-alert">
                <ul><li v-for="e, i in errors" :key="i">{{ e }}</li></ul>
              </v-alert>

              <div class="pg-text-field-row">
                <v-text-field
                  label="ユーザーID（メールアドレス）"
                  v-model="email"
                  type="email"
                  outlined
                  clearable
                  color="primary"
                  persistent-placeholder
                />
              </div>

              <div class="pg-text-field-row">
                <v-text-field
                  label="パスワード"
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                  @click:append="showPassword = !showPassword"
                  outlined
                  color="primary"
                  persistent-placeholder
                />
              </div>

              <div class="pg-button-row">
                <v-btn large depressed type="submit" class="pg-button" color="primary" dark>ログイン</v-btn>
              </div>

            </v-form>

            <p>
              <a href="/forgot_password">パスワードをお忘れの方はこちら</a>
            </p>

            <h4>プロダクトガイドのアカウントをお持ちでない方</h4>
            <p>
              <a href="/register">今すぐアカウント作成（無料）</a>
            </p>
          </div>
        </div>
      </div>
    </div>


    <pg-loading-view ref="pgLoadingView" />


  </v-app>
</template>

<script>
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import Common from './common.js'
import PgHeader from './pg_header.vue'
import PgLoadingView from './pg_loading_view.vue'

Vue.use(Vuetify)
const vuetify = new Vuetify({
  theme: {
    light: true,
    themes: {
      light: {
        primary: Common.colorGreen(),
        success: Common.colorGreen(),
        error: Common.colorRed()
      }
    }
  }
})

export default {
  vuetify,
  components: {
    'pg-header': PgHeader,
    'pg-loading-view': PgLoadingView
  },
  data() {
    return {
      showPassword: false,
      email: Common.email(),
      password: '',
      errors: null
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      this.errors = null
      let params = {
        email: this.email,
        password: this.password
      }
      this.$refs.pgLoadingView.showView()
      Common.axios(document).post('/auth/sign_in', params).then(response => {
        Common.login(this.email,
                     response.headers['access-token'],
                     response.headers['client'],
                     response.headers['uid'])
        let search = location.search.split('?')
        if (2 <= search.length) {
          let params = search[1].split('&')
          for (let i = 0; i < params.length; i++) {
            let kv = params[i].split('=')
            if (kv.length == 2 && kv[0] == 'redirect') {
              location.href = kv[1]
              return
            }
          }
        }
        location.href = '/'
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$refs.pgLoadingView.hideView()
      })
    }
  }
}
</script>

<style lang="scss">
@import "global.scss";
</style>

<style scoped lang="scss">
@import "scoped.scss";

.page-login {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 56px 0 0;
  background: $pg-bg-gradient;

  .pg-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .pg-content {
      width: 420px;
      padding: 0 0 56px;

      h3 {
        padding: 0 0 24px;
        text-align: center;
        color: white;
      }
      .pg-form-container {
        padding: 32px;
        background-color: white;

        .pg-alert {
          margin: 0 0 32px;
        }

        .pg-text-field-row {
          padding: 0 0 8px;
        }

        .pg-button-row {
          padding: 0 0 16px;
          display: flex;
          justify-content: center;
          .pg-button {
            width: 100%;
          }
        }

        p {
          margin: 0;
          text-align: center;
          font-size: 14px;
        }
        h4 {
          padding: 36px 0 16px;
        }
      }
    }
  }
}
</style>
