<template>
  <div class="pg-loading-view" v-if="show">
    <div class="pg-loading-view-content" ref="pgLoadingViewContent">
      <v-progress-circular :size="64" color="white" indeterminate />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false
    }
  },
  methods: {
    showView() {
      this.show = true
      setTimeout(() => {
        this.$refs.pgLoadingViewContent.classList.add('show')
      }, 200)
    },
    hideView() {
      if (this.$refs.pgLoadingViewContent) {
        this.$refs.pgLoadingViewContent.classList.remove('show')
      }
      setTimeout(() => {
        this.show = false
      }, 200)
    }
  }
}
</script>

<style scoped lang="scss">
@import "scoped.scss";

.pg-loading-view {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 900;

  .pg-loading-view-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $pg-bg-gradient;

    opacity: 0.0;
    transition: opacity 1.0s;
    &.show {
      opacity: 0.7;
    }
  }
}
</style>
